<script>
import {Doughnut} from 'vue-chartjs'
import {interpolateRdPu} from 'd3-scale-chromatic'

function calculatePoint(i, intervalSize, colorRangeInfo) {
  var { colorStart, colorEnd, useEndAsStart } = colorRangeInfo;
  return (useEndAsStart
    ? (colorEnd - (i * intervalSize))
    : (colorStart + (i * intervalSize)));
}

/* Must use an interpolated color scale, which has a range of [0, 1] */
function interpolateColors(dataLength, colorScale, colorRangeInfo) {
  var { colorStart, colorEnd } = colorRangeInfo;
  var colorRange = colorEnd - colorStart;
  var intervalSize = colorRange / dataLength;
  var i, colorPoint;
  var colorArray = [];

  for (i = 0; i < dataLength; i++) {
    colorPoint = calculatePoint(i, intervalSize, colorRangeInfo);
    colorArray.push(colorScale(colorPoint));
  }

  return colorArray;
}

export default {
  extends: Doughnut,
  props: ['data'],
  mounted() {
    const current_values = _.map(this.data.current_period, 'value_hash')
    const colors = interpolateColors(
      current_values.length,
      interpolateRdPu,
      {
        colorStart: 0.2,
        colorEnd: 1,
        useEndAsStart: true
      }
    )

    this.renderChart(
      {
        labels: _.map(this.data.current_period, 'value_text'),
        datasets: [
          {
            data: _.map(this.data.current_period, 'count'),
            backgroundColor: colors
          }
        ]
      },
      {
        //maintainAspectRatio: false,
        //aspectRatio: 1,
        legend: {
          position: 'left'
        }
      }
    )
  }
}
</script>